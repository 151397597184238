<template>
  <div class="mod_con titlesTop">
    <headTitle title="修改密码" style="background:#fff" />
    <div style="width：100%;height:20vh"></div>
    <div class="msgList">
      <span class="icon-mima iconfont"></span>
      <van-field v-model="oldPass" type="password" clearable placeholder="请输入原登录密码" />
    </div>
    <div class="msgList">
      <span class="icon-mima iconfont"></span>
      <van-field v-model="newPass" type="password" clearable placeholder="请设置6-16位新密码(包含字母和数字)" />
    </div>
    <div class="msgList">
      <span class="icon-mima iconfont"></span>
      <van-field v-model="rePass" type="password" clearable placeholder="请再次输入新密码" />
    </div>

    <!-- 确定按钮 -->
    <div class="btn_wra">
      <van-button
        class="btn_aut"
        type="primary"
        color="#e42417"
        :loading="btnLoading"
        loading-type="spinner"
        @click="onModify"
      >确定</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import authApi from "@/api/auth";

export default {
  data() {
    return {
      oldPass: "", // 原密码
      newPass: "", // 新密码
      rePass: "", // 重复密码
      btnLoading: false,
    };
  },
  beforeCreate() {
    let isLogin = this.isToken();
    if (!isLogin) {
      this.goBackPage();
    }
  },
  methods: {
    // 修改密码
    onModify() {
      const isAble = this.testPass();
      console.log(isAble);
      if (!isAble) {
        return;
      }
      const data = {
        oldPass: this.oldPass,
        newPass: this.newPass,
        memberId: JSON.parse(localStorage.personalInformation).id,
      };
      authApi
        .memberModifyPass(data)
        .then((res) => {
          if (res.success) {
            Toast("密码修改成功，请重新登录");
            this.clearAll();
          } else {
            Toast(res.message);
            // if (res.code === 10007) {
            //   Toast("旧密码错误，请检查后重新输入");
            // }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 规则验证
    testPass() {
      if (!this.oldPass) {
        Toast("请输入原密码");
        return false;
      }
      if (!this.newPass) {
        Toast("请输入新密码");
        return false;
      } else {
        // let rex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;
        // let result = rex.test(this.newPass);
        if (this.oldPass.length > 16 || this.oldPass.length < 6) {
          Toast("密码至少包含字母，数字，且在6到16位之间");
          return false;
        }
        if (this.newPass.length > 16 || this.newPass.length < 6) {
          Toast("密码至少包含字母，数字，且在6到16位之间");
          return false;
        }
        if (this.oldPass === this.newPass) {
          Toast("新密码不能与原密码相同");
          return false;
        }
      }
      if (!this.rePass) {
        Toast("请再次输入新密码");
        return false;
      } else {
        if (this.newPass !== this.rePass) {
          Toast("两次输入的密码不一致，请检查");
          return false;
        }
      }
      return true;
    },

    // 清空表单数据，清空本地数据
    clearAll() {
      this.oldPass = "";
      this.newPass = "";
      this.rePass = "";
      window.localStorage.clear(); //清除缓存
      this.$router.push("/myinfo");
    },
  },
};
</script>

<style lang="less" scoped>
.mod_con {
  // padding: 100px 20px 0 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  .msgList {
    margin: 10px 0;
    display: flex;
    justify-content: left;
    align-items: center;
    border-bottom: 1px solid #eee;
  }
  .btn_wra {
    // width: 80vw;
    height: 45px;
    margin: 40px auto 0;
    .btn_aut {
      width: 100%;
      height: 100%;
      font-size: 16px;
      font-weight: bold;
      line-height: 45px;
    }
  }
}
</style>
